require('normalize.css');
require('../../assets/css/main.scss');
require('./index.scss');

import pdfIcon from './images/pdf.svg';


const pdfIcons = document.getElementsByClassName('pdfIcon');
for (let i = 0; i < pdfIcons.length; i++) {
  pdfIcons[i].src = pdfIcon;
}
